import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

import { isMobile, isValidText } from '../../utils/helper'

export class AppMenu extends React.Component {
	parentRef = React.createRef()
	constructor(props) {
		super(props)
		this.state = { isOpen: false }
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.handleClickWindow, false)
	}

	open = (isOpen) => {
		if (isOpen) {
			window.addEventListener('click', this.handleClickWindow, false)
		} else {
			if (this.props.onClose) { this.props.onClose() }
		}
		this.setState(state => {
			if (this.parentRef.current) {
				const height = isOpen ? `calc(100vh - ${this.parentRef.current.getBoundingClientRect().top}px)` : "0px"
				this.parentRef.current.style.height = height

				if (isOpen && isMobile()) {
					document.getElementById("parent").classList.add('no-scroll')
				} else {
					document.getElementById("parent").classList.remove('no-scroll')
				}
			}
			return {
				...state,
				isOpen: isOpen
			}
		})
	}

	handleClick = (event) => {
		if (event) {
			event.preventDefault()
			event.stopPropagation()
		}

		const { cancelable = true } = this.props
		if (cancelable) { this.open(false) }
	}

	handleClickWindow = (event) => {
		const { cancelable = true } = this.props
		if (cancelable && !event.target.matches('#app-menu')) {
			this.open(false)
		}
	}

	render() {
		const { children } = this.props
		const { isOpen } = this.state

		return (
			<div ref={this.parentRef} className="app-menu" onClick={this.handleClick} >
				{isOpen &&
					<div id="app-menu" className="app-menu-content">
						{children}
					</div>
				}
			</div>
		)
	}
}

export function AppMenuItem({
	className = '',
	label,
	path,
	url,
	leftIcon,
	rightIcon,
	disabled,
	onClick = () => { }
}) {
	function handleClick(event) {
		if (disabled) {
			event.preventDefault()
			return
		}

		if (event) {
			event.stopPropagation()
			event.preventDefault()
		}

		onClick()
	}

	return (
		<div className={`app-menu-item ${className ? className : ''}`} onClick={handleClick}>
			{(!isValidText(path) && !isValidText(url)) &&
				<>
					{leftIcon && <img className="left" src={leftIcon} alt="" />}
					<span>{label}</span>
					{rightIcon && <img className="right" src={rightIcon} alt="" />}
				</>
			}
			{isValidText(path) &&
				<NavLink to={path} onClick={handleClick}>
					{leftIcon && <img className="left" src={leftIcon} alt="" />}
					<span>{label}</span>
					{rightIcon && <img className="right" src={rightIcon} alt="" />}
				</NavLink>
			}
			{isValidText(url) &&
				<a href={url} target="_blank" onClick={handleClick}>
					{leftIcon && <img className="left" src={leftIcon} alt="" />}
					<span>{label}</span>
					{rightIcon && <img className="right" src={rightIcon} alt="" />}
				</a>
			}
		</div>
	)
}

export function AppMenuDivider() {
	return (
		<div className="app-menu-divider"></div>
	)
}