import React from 'react'

import './styles.scss'

import {
	TopProgressBar, PublicHeader, AppFooter,
	Loader
} from '../../components'

import { Status } from '../../core'

export function DashboardScene({ }) {
	React.useEffect(() => {
		load()
		const timer = setInterval(() => {
			// load()
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	const [isLoading, setLoading] = React.useState(false)
	const [status, setStatus] = React.useState([])

	async function load() {
		setLoading(true)

		const { error, results } = await Status.getStatus()
		setStatus(results)

		setLoading(false)
	}

	function calcHasError() { return status.some(e => !e.healthy) }

	return (
		<div id="parent" className="page-container dashboard">
			<TopProgressBar />
			<PublicHeader />
			<section className="intro">System Status</section>
			<section className="all-system">{calcHasError() ? 'Systems' : 'All Systems Operational'}</section>
			<section className="status-list">
				{(isLoading && !status) ? <Loader /> :
					<>
						{status &&
							status.map((item, index) => {
								const disabled = !item.healthy
								return (
									<div key={index} className="status-list-item">
										<div>{item.label}</div>
										<img className={`badge ${disabled && 'disabled'}`} />
									</div>
								)
							})}
					</>
				}
			</section>
			<AppFooter />
		</div>
	)
}