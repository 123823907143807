import React from 'react'

import './styles.scss'

import lock from './images/lock.svg'
import lockMobile from './images/lock-mobile.svg'
import instagram from './images/instagram.svg'
import twitter from './images/twitter.svg'
import twitterGreen from './images/twitter-green.svg'
import linkedin from './images/linkedin.svg'
import linkedinGreen from './images/linkedin-green.svg'
import facebook from './images/facebook.svg'

import { AppConfig } from '../../constants'

export class AppFooter extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { className, children } = this.props
		return (
			<div className={`footer ${className ? className : ''}`}>
				<div className="footer-above">
					<div className="footer-left">
						<div>
							<img className="footer-lock" src={lock} alt="" />
							<img className="footer-lock-mobile" src={lockMobile} alt="" />
							<li><a href="https://lockrmail.com/faq" target="_blank">FAQ</a></li>
							<li><a href="https://lockrmail.com/contact" target="_blank">Contact</a></li>
							<li><a href="https://lockr.workable.com/" target="_blank">Careers</a></li>
						</div>
						<div>
						</div>
					</div>
					<div className="footer-right">
						<div className="footer-right-social">
							<li className="footer-right-social-item">
								<a href="https://twitter.com/lockr_digital" target="_blank">
									<img src={twitter} alt="" />
									<img src={twitterGreen} alt="" />
								</a>
							</li>
							<li className="footer-right-social-item">
								<a href="https://www.linkedin.com/company/digital-lockr/" target="_blank">
									<img src={linkedin} alt="" />
									<img src={linkedinGreen} alt="" />
								</a>
							</li>
						</div>
					</div>
				</div>
				<div className="footer-legal">
					<li><a href="/">Application Status</a></li>
					<li><a href={AppConfig.PRIVACY_URL}>Privacy Policy</a></li>
					<li><a href={AppConfig.TERMS_URL}>Terms of Service</a></li>
					<li>© 2020 <a href="https://loc.kr" target="_blank">lockr</a></li>
				</div>
				{children}
			</div>
		)
	}
}