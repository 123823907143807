import axios from 'axios'

import { AppConfig } from '../constants'
import { isValidText } from '../utils'
import { Storage } from '../utils'

const BASE_URL = AppConfig.API_BASE_URL

export class Api {
	static handleResponse(response) {
		try {
			if (!response.data.error) { return response.data }
			const result = response.data.error.message
			const errorCode = response.data.error.code
			if (isValidText(result, true)) {
				let message = result.replace('Error: ', '')
				if (message.includes("jwt malformed")) {
					message = "Your credentials were not registered in system."
				}
				if (message.includes("jwt expired") || errorCode === 401) {
					message = "The session has expired. Please refresh the page and login to your account to return to the dashboard."
				}
				return { success: false, error: message, errorCode }
			}
			const { msg, param, value } = response.data.error.errors[0]
			return { success: false, param: param, error: msg, errorCode, errorValue: value }
		} catch (error) {
			console.log(error)
		}
		return response.data
	}

	static parseError(error, placeholder = "Something went wrong") {
		if (error.isAxiosError) {
			return placeholder
		}

		if (error.message && error.message !== "") {
			return error.message
		}

		const { msg, param } = error.errors[0]
		return msg
	}

	static get headers() {
		if (!Storage.accessToken) return axios.defaults.headers
		return { ...axios.defaults.headers, Authorization: `Bearer ${Storage.accessToken}` }
	}

	static createConfiguration() {
		return {
			...axios.defaults,
			headers: {
				...Api.headers,
				'x-api-key': 'L8vobjMCV46Y0uT2sRRue1fovAcS26Tq5CD0FXMK'
			}
		}
	}

	static async refreshAccess() {
		const refreshToken = Storage.refreshToken
		if (!refreshToken) return { success: false }
		try {
			const { success, data, message } = Api.handleResponse(await axios.post(BASE_URL + '/refresh-access', { refreshToken: refreshToken }))
			if (success) Storage.setAccessToken(data.accessToken)
			return { success }
		} catch (error) {
			console.log(error)
			return { success: false }
		}
	}

	static async get(path, param = undefined) {
		let query = ''
		if (param) {
			query = '?'
			let keys = Object.keys(param)
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i]
				query += `${key}=${encodeURIComponent(param[key])}`
				if (i < keys.length - 1) {
					query += '&'
				}
			}
		}

		try {
			const requestPath = BASE_URL + path + query
			const response = await axios.get(requestPath, Api.createConfiguration())
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.get(path, param)
				}
			}

			throw error
		}
	}

	static async post(path, param = undefined) {
		try {
			const response = await axios.post(BASE_URL + path, param, Api.createConfiguration())
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.post(path, param)
				}
			}

			throw error
		}
	}

	static async put(path, param = undefined) {
		try {
			const response = await axios.put(BASE_URL + path, param, Api.createConfiguration())
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.put(path, param)
				}
			}

			throw error
		}
	}

	static async delete(path, params = null) {
		try {
			const response = await axios.delete(BASE_URL + path, { headers: Api.headers, data: params })
			return Api.handleResponse(response)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				const { success } = await Api.refreshAccess()
				if (success) {
					return await Api.put(path, params)
				}
			}

			throw error
		}
	}
}

export function onSuccess(type, response = undefined) {
	return { type: type, response: response }
}

export function onFailure(type, error) {
	return { type: type, error: error }
}
