import { Api } from '../'
import { ErrorMessages } from '../../constants'


const Tags = [
	['app'],
	['api'],
	['middleware', 'mailserver'],
]
const TagLabels = [
	'Application',
	'API',
	'Mail servers',
]

export class Status {
	tag
	label
	healthy

	constructor({ tag, label, healthy = false }) {
		this.tag = tag
		this.label = label
		this.healthy = healthy
	}

	static parseResponse(response) {
		let results = Tags.map((tag, index) => new Status({ tag, label: TagLabels[index] }))
		if (!response) return results

		const { body } = response
		const { report = [] } = body ? JSON.parse(body) : {}

		// Parse "healthy"
		for (let result of results) {
			const { tag } = result

			let healthy = true
			for (let tagItem of tag) {
				if (!report.some((e) => e.tag.includes(tagItem))) continue

				const reportItem = report.filter(e => e.tag.includes(tagItem))[0]
				healthy = healthy && reportItem.healthy
			}

			result.healthy = healthy
		}

		return results
	}

	static async getStatus() {
		try {
			const response = await Api.get('/')
			return { error: response.error, results: Status.parseResponse(response) }
		} catch (error) {
			return { error: Api.parseError(error, ErrorMessages.NETWORK_ERROR) }
		}
	}
}