import { isValidText } from "./helper"

const Keys = {
	Email: "Email",
	Password: "Password",
	AccessToken: "AccessToken",
	RefreshToken: "RefreshToken",
	WelcomePopupCount: 'WelcomePopupCount',
	ChecklistMinimized: 'lockrmail-checklist-minimized',
	ChecklistAnimated: 'lockrmail-checklist-animated'
}
export class Storage {
	static save(key, value) {
		if (value) localStorage.setItem(key, value)
		else localStorage.removeItem(key)
	}

	static get(key, defaultValue = undefined) {
		return localStorage.getItem(key) || defaultValue
	}

	static get accessToken() { return Storage.get(Keys.AccessToken) }
	static setAccessToken(accessToken) {
		if (!isValidText(accessToken, true)) {
			localStorage.removeItem(Keys.AccessToken)
			return
		}
		Storage.save(Keys.AccessToken, accessToken)
	}

	static get refreshToken() { return Storage.get(Keys.RefreshToken) }
	static setRefreshToken(refreshToken) {
		if (!isValidText(refreshToken)) {
			localStorage.removeItem(Keys.RefreshToken)
			return
		}

		Storage.save(Keys.RefreshToken, refreshToken)
	}

	static get welcomePopupCloseCount() { return parseInt(Storage.get(Keys.WelcomePopupCount)) || 0 }
	static increaseeWelcomePopupCloseCount() {
		Storage.save(Keys.WelcomePopupCount, Storage.welcomePopupCloseCount + 1)
	}

	static get checklistMinimized() {
		// console.log(Storage.get(Keys.ChecklistClosed, false))
		return Storage.get(Keys.ChecklistMinimized, false)
	}
	static setChecklistMinimized(value = true) {
		Storage.save(Keys.ChecklistMinimized, value)
	}

	static get checklistAnimated() { return Storage.get(Keys.ChecklistAnimated, false) }
	static setChecklistAnimated(value = true) { Storage.save(Keys.ChecklistAnimated, value) }
}