export class AppConfig {
	static get hostname() {
		const result = window && window.location && window.location.hostname
		if (!result || result === "") {
			console.log("hostname is not ready.")
		}
		return result
	}
	static get API_BASE_URL() {
		// if (AppConfig.hostname === "dev-app.lockrmail.com") return "https://dev-backend.lockrmail.com/api/v1"
		// if (AppConfig.hostname === "staging-app.lockrmail.com") return "https://staging-backend.lockrmail.com/api/v1"
		// if (AppConfig.hostname === "app.lockrmail.com") return "https://api.lockrmail.com/api/v1"
		// return "https://dev-backend.lockrmail.com/api/v1"
		return 'https://status-backend.lockrmail.com'
	}
	static get RSA_PUBLIC_KEY() {
		if (AppConfig.hostname === "dev-app.lockrmail.com") return "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqCbGOh5zIBaFsnrk6d/n\npvfVoXPFHiRujvrf1gqNxn/nKOL9Qh8rT855v9jkOlKHPytzZKlVrfXWEDVnaaLD\nFw3GLcARA6M8nXXY9yonVkD9YlnPSIrV6J/Z/6VYdpEODLxvIuZ6AuBdDT+6H+Pk\nTEYI594ILC7R+M/VUKphpjVp90NdTw6LH4uTBvSUINTVTtBUUDblSg0z256V+USd\nBWLAlooPnPaedXW6/KBJMvvL7S/rFjeKm+hMmBUYNFnOQVB0bEgq3Ehx8KAO1PbD\nz+gm07kS/InPTaYf7txuMxuVopuEcjuYYhUB7lkVWsDzOzK825tkdrFIXFC0NSZV\nTQIDAQAB\n-----END PUBLIC KEY-----\n"
		if (AppConfig.hostname === "staging-app.lockrmail.com") return "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw8iXYcalxESgL+J4wqQ9\nGfEngrkboNSngFrG7KMEtdEwIdZw7LhCxmQDgg9/yOuRrzproyOx9hDerL7afkNj\nGPy4xcqTHeb0+ZSn5U7MPka623gUVqhLoJSx7TVW06uuRQU/1zm76OvLmzuisOgC\nmPfmTfIaWQLFT95Y2GAi999XlqxX6GBnqfam6ibp9t+kpGtPHnILY9eCbuNlQGwG\nIBCc6FjiLFWuSOE3WJQTsqsV6ruzPxKYYqxcpvVVDMtyoGHk6C4eePAVcUyfMD3Z\nq4F+Na5BPKv+gJFLTkJz6a4s2x2uOJqC/82T1iHbJt/imL8I8kr009Y11YnHMnmA\npQIDAQAB\n-----END PUBLIC KEY-----\n"
		if (AppConfig.hostname === "app.lockrmail.com") return "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlaaNE9NMTcHPGg0Ky5Ri\n57/pOixIp6oSem5LGfyJeXBuYunJvZ+2UhcNXiN6tqPI0cHZm1psRBgwt8TtgY1X\n3t9nUH9q4RSdsF2BfKMYX2xMXEXVEpzkV4pf9WNc17QMQILZCJWNyk8SnHzgSxYB\nNDMQWqGcLW2igEF/vz9aU+hvt3R4lb8dCi3t6rj/tqEsSkdgXezCHBi7d4Z/eTbY\nRNN163cwUrSMHVvNt6EjABeMbx1yLPndj3YH6/c7j4s73jUP3TfTJhafR7k9VciH\n3rJajp+pttXfQQHqKg5+ApZ/DJKJHiwrLv36J4axF7gZBWYKZbw9vz50dL5hd/rh\n5QIDAQAB\n-----END PUBLIC KEY-----\n"
		return "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqCbGOh5zIBaFsnrk6d/n\npvfVoXPFHiRujvrf1gqNxn/nKOL9Qh8rT855v9jkOlKHPytzZKlVrfXWEDVnaaLD\nFw3GLcARA6M8nXXY9yonVkD9YlnPSIrV6J/Z/6VYdpEODLxvIuZ6AuBdDT+6H+Pk\nTEYI594ILC7R+M/VUKphpjVp90NdTw6LH4uTBvSUINTVTtBUUDblSg0z256V+USd\nBWLAlooPnPaedXW6/KBJMvvL7S/rFjeKm+hMmBUYNFnOQVB0bEgq3Ehx8KAO1PbD\nz+gm07kS/InPTaYf7txuMxuVopuEcjuYYhUB7lkVWsDzOzK825tkdrFIXFC0NSZV\nTQIDAQAB\n-----END PUBLIC KEY-----\n"
	}

	static get LOCKR_URL() {
		return "https://lockrmail.com"
	}

	static get PRIVACY_URL() { return AppConfig.LOCKR_URL + "/privacy-policy" }
	static get CONTACT_URL() { return AppConfig.LOCKR_URL + "/contact" }
	static get TERMS_URL() { return AppConfig.LOCKR_URL + "/terms-of-service" }
	static get HELP_CENTER_URL() { return AppConfig.LOCKR_URL + "/help-center" }

	static get APP_URL() {
		return "https://app.lockrmail.com"
	}
}