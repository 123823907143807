import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './assets/styles/App.scss'
import { DashboardScene } from "./scenes/dashboard"

/* define all routes */
export default () => {

	React.useEffect(() => {
		window.addEventListener('scroll', handleWindowScroll)
		return () => {
			window.removeEventListener('scroll', handleWindowScroll)
		}
	})

	function handleWindowScroll() {
		document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
	}

	return (
		<Router>
			<Switch>
				<Route exact path="/" component={DashboardScene} />
			</Switch>
		</Router>
	)
}
