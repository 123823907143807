import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader, AppHeaderRightContainer, AppHeaderRightItem } from '../app-header'
import { AppMenu, AppMenuItem, AppMenuDivider } from '../app-menu'

import { AppConfig } from '../../constants'

class MenuItem {
	className
	leftIcon
	label
	rightIcon
	path
	url
	divider = false
	skip
	onClick

	constructor({ className = '', leftIcon, label, rightIcon, path, url, divider, skip, onClick }) {
		this.className = className
		this.leftIcon = leftIcon
		this.label = label
		this.rightIcon = rightIcon
		this.path = path
		this.url = url
		this.divider = divider || false
		this.skip = skip || false
		this.onClick = onClick
	}
}


export function PublicHeader({
	className = '',
	children
}) {
	const history = useHistory()

	const appMenuRef = React.createRef()
	const [isOpenMenu, setIsOpenMenu] = React.useState(false)
	React.useEffect(() => {
		if (appMenuRef.current) { appMenuRef.current.open(isOpenMenu) }
	}, [isOpenMenu])

	function handleMenu() {
		setIsOpenMenu(!isOpenMenu)
	}

	function handleItem(item) {
		if (item.onClick) {
			item.onClick()
			return
		}

		if (item.path) history.push(item.path)
		if (item.url) window.open(item.url, '_blank')
	}

	function handleItemClick(item) {
		if (appMenuRef.current) { appMenuRef.current.open(false) }

		handleItem(item)
	}

	function menuItems() {
		let results = [
			new MenuItem({ label: 'FAQ', url: `${AppConfig.LOCKR_URL}/faq`, }),
			new MenuItem({ label: 'Blog', url: `${AppConfig.LOCKR_URL}/blog`, }),
			new MenuItem({ label: 'Contact', url: `${AppConfig.LOCKR_URL}/contact` }),
			new MenuItem({ label: 'Sign in', url: `${AppConfig.APP_URL}/login` })]
		return results
	}

	return (
		<>
			<AppHeader className={className} onLogo={(event) => handleItemClick(new MenuItem({ path: '/' }))} onMenu={() => handleMenu()}>
				<AppHeaderRightContainer>
					{menuItems().map((menuItem, index) => <AppHeaderRightItem key={index} {...menuItem} />)}
				</AppHeaderRightContainer>
			</AppHeader>
			<AppMenu ref={appMenuRef} onClose={() => setIsOpenMenu(false)}>
				{menuItems().filter(item => !item.skip).map((item, index) => {
					if (item.divider) return <AppMenuDivider key={index} />
					return <AppMenuItem key={index} {...item} onClick={() => handleItemClick(item)} />
				})}
			</AppMenu>
		</>
	)
}