const moment = require('moment-timezone')

export class DateHelper {
	static moment(date) {
		return moment.tz(date, moment.tz.guess(true))
	}

	static parse(value) {
		// console.log(value)
		if (!value) return null
		return moment.tz(value, moment.tz.guess(true)).toDate()
	}

	static format(date, format = 'YYYY-MM-DD hh:mm A') {
		if (!date) { return '' }
		return moment.tz(date, moment.tz.guess(true)).format(format)
	}

	// Compare
	static isSameDay(a, b) {
		if (!a || !b) { return false }

		const format = 'YYYY-MM-DD'
		return DateHelper.format(a, format) === DateHelper.format(b, format)
	}

	static isSameYear(a, b) {
		if (!a || !b) { return false }

		const format = 'YYYY'
		return DateHelper.format(a, format) === DateHelper.format(b, format)
	}

	// Get
	static getDayOfYear(date = new Date()) {
		return parseInt(DateHelper.format(date, 'DDD DDDD'))
	}

	static getHour(date = new Date()) {
		const result = DateHelper.format(date, 'HH')
		return parseInt(result)
	}

	static getMinute(date = new Date()) {
		const result = DateHelper.format(date, 'mm')
		return parseInt(result)
	}

	static getAmPm(date = new Date()) {
		const result = DateHelper.format(date, 'a')
		return result
	}

	// Calculation
	static startOf(date = new Date(), params) {
		return moment.tz(date, moment.tz.guess(true)).startOf(params).toDate()
	}

	static addHour(date = new Date(), hours) {
		return moment.tz(date, moment.tz.guess(true)).add(hours, 'hours').toDate()
	}

	static addMinute(date = new Date(), minutes) {
		return moment.tz(date, moment.tz.guess(true)).add(minutes, 'minutes').toDate()
	}

	static addDay(date = new Date(), days) {
		return moment.tz(date, moment.tz.guess(true)).add(days, 'days').toDate()
	}

	static addMonth(date = new Date(), months) {
		return moment.tz(date, moment.tz.guess(true)).add(months, 'months').toDate()
	}

	static addYear(date = new Date(), years) {
		return moment.tz(date, moment.tz.guess(true)).add(years, 'years').toDate()
	}
}