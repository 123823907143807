import React, { useState } from "react"
import { NavLink, useHistory } from 'react-router-dom'

import './styles.scss'

import iconLogo from "../../assets/images/lockr-logo.svg";
import iconHamburger from "../../assets/images/hamburger.svg";
import { isValidText } from "../../utils/helper";

export function AppHeader(props) {
	const { className, disabled, children, onLogo, onMenu } = props
	const history = useHistory()

	function handleOnMenu(event) {
		event.preventDefault()
		event.stopPropagation()

		if (onMenu) {
			onMenu()
		}
	}

	function handleLogoClick(event) {
		if (disabled) event.preventDefault()
		if (onLogo) onLogo()
	}

	return (
		<div id="app-header" className={`app-header ${className ? className : ''}`}>
			<div className="app-header-content">
				<a href="/" onClick={handleLogoClick}><img src={iconLogo} alt="Lockr.io" /></a>
				{children}
				<div className="app-header-content-hamburger" onClick={handleOnMenu}>
					<img src={iconHamburger} alt="Menu" />
				</div>
			</div>
		</div>
	)
}

export function AppHeaderRightContainer(props) {
	const { children } = props

	return (
		<div className="app-header-content-right">
			{children}
		</div>
	)
}

export function AppHeaderRightItem({
	label,
	url,
	path,
	leftIcon,
	rightIcon,
	disabled = false,
	onClick = () => { }
}) {

	function handleClick(event) {
		if (event) {
			if (disabled) event.preventDefault()
			event.stopPropagation()
		}
		if (onClick) onClick()
	}

	function renderContent() {
		if (path) return (
			<NavLink to={path} onClick={handleClick}>
				{leftIcon && <img src={leftIcon} alt="" />}
				{label}
				{rightIcon && <img src={rightIcon} alt="" />}
			</NavLink>
		)

		if (url) return (
			<a href={url} target="_blank">
				{leftIcon && <img src={leftIcon} alt="" />}
				{label}
				{rightIcon && <img src={rightIcon} alt="" />}
			</a>
		)

		return (
			<>
				{leftIcon && <img src={leftIcon} alt="" />}
				{label}
				{rightIcon && <img src={rightIcon} alt="" />}
			</>
		)
	}

	return (
		<div className="app-header-item" onClick={event => handleClick(event)}>
			{renderContent()}
		</div>
	)
}
