import _ from 'lodash'

import { isValidText } from './helper'
import { ArrayHelper } from './array-helper'

function is(val) {
	return val !== null && typeof val === 'object'
}

function isValid(val, property = null) {
	if (val === null || val === undefined) return false
	if (!isValid(property)) return true
	return isValid(value(val, property))
}


function value(val, path) {
	if (val === null || val === undefined) return null
	if (!isValidText(path)) return val

	const curPath = path.split('.')[0]
	if (curPath === path) return val[path]
	return value(val[curPath], path.replace(`${curPath}.`, ''))
}

function equals(a, b) {
	if (a === b) return true
	if (ArrayHelper.is(a) && ArrayHelper.is(b)) return ArrayHelper.equals(a, b)
	if (!is(a) || !is(b)) {
		return false
	}

	const keysA = Object.keys(a)
	const keysB = Object.keys(b)

	if (!ArrayHelper.equals(keysA, keysB)) return false

	const result = keysA.map(key => equals(a[key], b[key])).some(e => !e)
	return result ? false : true
}

function copy(val) {
	if (val === null || val === undefined) return null
	return _.cloneDeep(val)
}

export const ObjectHelper = {
	is, isValid, value, equals,
	copy
}