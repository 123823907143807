/*global chrome*/

require('dotenv').config()

const EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID

function checkInstalled(callback = (result) => { }) {
	// callback(true)
	// return

	try {
		if (!chrome) {
			callback(false)
			return
		}

		chrome.runtime.sendMessage(EXTENSION_ID, { message: "version" }, response => {
			callback(Boolean(response))
		})
	} catch (error) {
		console.log(error)
		callback(false)
	}
}

export const ExtensionHelper = {
	checkInstalled
}