import React, { useEffect } from 'react'

import './styles.scss'

export function TopProgressBar(props) {
	const ref = React.createRef()
	const { progress = 100, isHidden } = props

	function update() {
		if (!ref.current) { return }
		if (isHidden) {
			ref.current.style.display = 'none'
			return
		}

		ref.current.style.width = `${progress}%`
	}

	useEffect(() => { update() }, [])

	return (
		<div ref={ref} className="top-progress-bar" />
	)
}
